import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import length from "ramda/src/length"
import gt from "ramda/src/gt"
import compose from "ramda/src/compose"
import toLower from "ramda/src/toLower"
import includes from "ramda/src/includes"
import __ from "ramda/src/__"
import filter from "ramda/src/filter"
import path from "ramda/src/path"
import deburr from "lodash/deburr"

import useKeyPress from "./useKeyPress"

const gtThanZero = gt(__, 0)
const getTitle = path(["node", "frontmatter", "title"])
const getCities = path(["allMarkdownRemark", "edges"])

export default function useSelectCity(term) {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        totalCount
        edges {
          node {
            htmlAst
            frontmatter {
              title
            }
            parent {
              ... on File {
                relativeDirectory
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  const [cursor, setCursor] = React.useState(0)

  const downPress = useKeyPress("ArrowDown")
  const upPress = useKeyPress("ArrowUp")

  const hasTerm = React.useCallback(compose(includes, deburr, toLower)(term), [
    term,
  ])

  const searchCitiesByTerm = React.useCallback(
    filter(compose(hasTerm, deburr, toLower, getTitle)),
    [hasTerm]
  )

  const cities = React.useMemo(() => getCities(data), [data])

  const filteredCities = React.useMemo(() => searchCitiesByTerm(cities), [
    cities,
    searchCitiesByTerm,
  ])

  const selectPrevCity = React.useCallback(() => {
    if (term && gtThanZero(length(filteredCities))) {
      setCursor(prevState =>
        prevState > 0 ? prevState - 1 : length(filteredCities) - 1
      )
    }
  }, [term, filteredCities, setCursor])

  const selectNextCity = React.useCallback(() => {
    if (term && gtThanZero(length(filteredCities))) {
      setCursor(prevState =>
        prevState < length(filteredCities) - 1 ? prevState + 1 : 0
      )
    }
  }, [term, filteredCities, setCursor])

  React.useEffect(() => {
    if (downPress) {
      selectNextCity()
    }
  }, [downPress, selectNextCity])

  React.useEffect(() => {
    if (upPress) {
      selectPrevCity()
    }
  }, [upPress, selectPrevCity])

  return { cities: filteredCities, cursor, setCursor, selectNextCity }
}
