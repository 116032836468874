import path from "ramda/src/path"
import prop from "ramda/src/prop"
import compose from "ramda/src/compose"
import toUpper from "ramda/src/toUpper"
import filter from "ramda/src/filter"
import has from "ramda/src/has"
import not from "ramda/src/not"
import map from "ramda/src/map"
import split from "ramda/src/split"
import head from "ramda/src/head"
import join from "ramda/src/join"
import init from "ramda/src/init"
import concat from "ramda/src/concat"
import last from "ramda/src/last"
import __ from "ramda/src/__"
import gt from "ramda/src/gt"
import ifElse from "ramda/src/ifElse"
import length from "ramda/src/length"

const greaterThanOne = gt(__, 1)
const arrayGreaterThanOne = compose(greaterThanOne, length)

const getCityName = path(["frontmatter", "title"])

const getStateName = compose(toUpper, path(["parent", "relativeDirectory"]))

const getPlaces = compose(
  map(compose(split("\n"), path(["children", "0", "value"]))),
  filter(compose(not, has("value"))),
  path(["htmlAst", "children"])
)

const getPlaceName = prop(0)
const getPlaceStreet = prop(1)
const getPlaceInformation = prop(2)

function getFormattedPlacesName(city) {
  const placesNameArray = compose(
    map(getPlaceName),
    getPlaces,
    prop("node"),
    head
  )(city)

  const placesName = ifElse(
    arrayGreaterThanOne,
    compose(
      concat(__, last(placesNameArray)),
      concat(__, " e "),
      join(", "),
      init
    ),
    head
  )(placesNameArray)

  return placesName
}

export {
  getCityName,
  getStateName,
  getPlaces,
  getPlaceName,
  getPlaceStreet,
  getPlaceInformation,
  getFormattedPlacesName,
}
